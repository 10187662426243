@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~ngx-toastr/toastr';
@import "~ng-pick-datetime/assets/style/picker.min.css";

@font-face {
    font-family: 'Gilroy';
    src: url("assets/fonts/Gilroy-Regular.ttf");
}
@font-face {
    font-family: 'Allison-Regular';
    src: url('assets/fonts/Allison-Regular.ttf');
}
@font-face {
    font-family: 'Dancing-Regular';
    src: url('assets/fonts/DancingScript-Regular.ttf');
}
@font-face {
    font-family: 'Saint-Regular';
    src: url('assets/fonts/MrsSaint-Regular.ttf');
}

@font-face {
    font-family: 'Gilroy-Bold';
    src: url("assets/fonts/Gilroy-Bold.ttf");
}
@font-face {
    font-family: 'Gilroy-ExtraBold';
    src: url("assets/fonts/Gilroy-ExtraBold.ttf");
}
@font-face {
    font-family: 'Gilroy-SemiBold';
    src: url("assets/fonts/Gilroy-SemiBold.ttf");
}
@font-face {
    font-family: 'Gilroy-Medium';
    src: url("assets/fonts/Gilroy-Medium.ttf");
}
@font-face {
    font-family: 'Arabic-Plain';
    src: url('assets/fonts/Bahij_TheSansArabic-Plain.ttf');
}
@font-face {
    font-family: 'Arabic-Bold';
    src: url('assets/fonts/Bahij_TheSansArabic-Bold.ttf');
}

@font-face {
    font-family: 'boiling-bold';
    src: url('assets/fonts/Boiling-BlackDemo.ttf');
}

$bg-light-gray-1: #F7F9FA;
$dark-blue: #0C4D71;
$bg-gradient-1: #006FB8;
$bg-gradient-2: #004B99;

.main[dir='ltr']{
    font-family: 'Gilroy';
}

.main[dir='rtl'] {
    font-family: 'Arabic-Plain';
    .Gilroy-Bold {
        font-family: 'Arabic-Bold';
    }
    .Gilroy-SemiBold {
        font-family: 'Arabic-Bold';
    }
    .Gilroy-Medium {
        font-family: 'Arabic-Bold';
    }
}

// .main[dir = 'rtl'] {
//     font-family: 'Arabic-Plain' !important;
//     .Gilroy-Bold {
//         font-family: 'Arabic-Bold';
//     }

//     .group-span-gilroy {
//         span {
//             font-family: 'Arabic-Bold';
//         }
//     }

//     .group-div-gilroy {
//         div {
//             font-family: 'Arabic-Bold';
//         }
//     }

//     .Gilroy-ExtraBold {
//         font-family: 'Arabic-Bold'
//     }

//     .group-a-gilroy {
//         a {
//             font-family: 'Arabic-Bold';
//         }
//     }

//     pre {
//         font-family: 'Arabic-Plain';
//     }
// }

.Allison-Regular {
    font-family: 'Allison-Regular';
}
.Dancing-Regular {
    font-family: 'Dancing-Regular';
}
.Saint-Regular {
    font-family: 'Saint-Regular';
}
.bg-gradient {
    background: transparent linear-gradient(77deg, $bg-gradient-1 0%, $bg-gradient-2 100%) 0% 0% no-repeat padding-box;
}
.bg-sidebar {
    background: transparent linear-gradient(180deg, $bg-gradient-1 0%, $bg-gradient-2 100%) 0% 0% no-repeat padding-box;;
}
.bg-proile {
    background: #F7F9FA;
}
.Gilroy-Bold {
    font-family: 'Gilroy-Bold'
}
.Gilroy-ExtraBold {
    font-family: 'Gilroy-ExtraBold'
}
.Gilroy-SemiBold {
    font-family: 'Gilroy-SemiBold'
}
.Gilroy-Medium {
    font-family: 'Gilroy-Medium'
}
.Gilroy {
    font-family: 'Gilroy';
}
html {
    scroll-behavior: smooth;
}
button:focus, input:focus, textarea:focus, select:focus {
    outline: none;
}
.active-border-invoice {
    border-color: #0C4D71;
}
input [type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select
{
    background-color: transparent;
}
.active-border {
    border-bottom: 4px solid #0C4D71;
    color: rgb(5, 5, 5);
}
.bg-image {
    background-image: url('./assets/images/bg-all.png');
    background-repeat: round;
}
.bg-input {
    background-color: #F7F9FA;
}
.bg-input-sales
{
    background-color: #F7F9FA;
}
.bg-light-gray-1 {
    background-color: $bg-light-gray-1;
}
.font-color-black-03 {
    color: rgba(0,0,0,0.3)
}
.font-color-black-05 {
    color: rgba(0,0,0,0.5)
}

.font-color-green {
    color:#059CA4;
    opacity: 1;
}
.dropdown-shadow {
    box-shadow: 1px 3px 18px #00000012;
}

.custom-input-box-shadow {
    box-shadow: inset 0px 0px 10px #00000033;
}

.box-shadow-sales {
    box-shadow: 0px 0px 14px #0000001A;
}
.back-chnage-custom.ng-select .ng-select-container {
    background: #f7f9fa !important;
    border-color: #f7f9fa !important;
}
.back-chnage-custom.ng-select .ng-select-container .ng-value-container
{
    padding-left: 0;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.mt-0-one
{
    margin-top: 0 !important;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.ss-single-selected {
    color: black;
    padding: 0 !important;
    border: none !important;
}

.industry-select {
    .ss-single-selected {
        color: black !important;
        padding: 0 !important;
        border: none !important;
        background-color: transparent !important;
        .placeholder {
            .ss-disabled {
                color: black !important;
                padding: 0.5rem 0 !important;
            }
        }
    }
    .ss-content {
        margin-top: 10px;
        margin-left: -20px !important;
    }
}

.box-drag
{
border: 2px dashed #0C4D71;
border-radius: 20px;
}
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.ng-select.custom-selector .ng-select-container {
    border: none;
    border-radius: 0.75rem;
    background-color: rgb(243, 244, 246);
    padding: 0.25rem;
    min-height: 46px;
    height: auto;
}
.ng-select.custom-selector1 .ng-select-container {
    border: none;
    border-radius: 0.75rem;
    background-color: transparent;
    padding: 0.25rem;
    min-height: 46px;
    height: auto;
}
.ng-select.settings-select .ng-select-container {
    border: none;
    border-radius: 0.75rem;
    background-color: transparent;
    padding: 0rem;
    min-height: 30px;
    height: auto;
}
.ng-value-container.ng-select.settings-select {
    padding: 0 !important;
}
.formSelect .ss-main .ss-single-selected
{
 height: 3rem !important;
 background-color: #F7F9FA !important;
}
.formSelect .ss-content.ss-open
{
    margin-left: -22px;
}

.border-custom
{
    border: 1px solid #0C4D71;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup{
    font-weight: bold;
    color: #000;
  }
  ol.orderList li {
    list-style: decimal;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
}

.active-tabs li.active
{
    border-bottom: 4px solid $bg-gradient-2;
}

// custom-scroll-bar

/* width */
#business-account::-webkit-scrollbar {
    width: 10px;
    height: 16px;
  }

  /* Track */
  #business-account::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #edf2f7;
  }

  /* Handle */
  #business-account::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 100vh;
    border: 3px solid #edf2f7;
  }

  /* Handle on hover */
  #business-account::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
  }

  .background-landing
{
    background: transparent linear-gradient(105deg, $bg-gradient-1 0%, $bg-gradient-2 100%) 0% 0% no-repeat padding-box;
}

.banner-landing {
    background: transparent linear-gradient(
119deg, $bg-gradient-1 0%, $bg-gradient-2 100%) 0% 0% no-repeat padding-box;
    margin-top: 4rem;
    min-height: 500px;
}

.w-fit {
     width: fit-content;
}



.banner-ipad img {
    text-align: center;
    margin: 0 auto;
    display: block;
    // position: absolute;
    // top: 15rem;

    width: 80%;
}


.box-shadow-landing
{
    box-shadow: 0px 0px 60px #00000012;
}
.color-blue
{
    color: $bg-gradient-2;
}
.gray-bg
{
    background: #F3F5FA 0% 0% no-repeat padding-box;
}

.super-color
{
    color: #8D8D8D;
}

.color-border
{
    border-color: #000000;
}

.bg-input-landing
{
    background-color: #DBDDEA;
}

.current {
    border-bottom: 2px solid $bg-gradient-2;
}

.bg-contact
{
    background-color: rgba(255,255,255,0.2);
}

#landing-tabs ul {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    text-align: center;
}

#landing-tabs ul li a {
    font-size: 14px;
}

#landing-tabs ul {
    border-bottom: 0;
}

#landing-tabs.active-tabs li.active
{
    border-bottom: 4px solid $bg-gradient-2;
}

details > summary {
    list-style-type: none;
}
#navbar {
    transition: all 0.5s;
  }
    .scrollUp {
      transform: translateY(-100px);
    }

details > summary::-webkit-details-marker {
    display: none;
}

details > summary::after {

    content: "+";
    font-weight: bold;
    font-size: 2rem;
    // border:1px solid gray;
    border-radius: 100%;
    width: 2.9rem;
    padding-left: 13px;
    padding-bottom: 46px;
    height: 1.9rem;

}

details[open] > summary::after {
    content: '-';
   font-weight: bold;
    font-size: 2rem;
}



details[open] > summary {
    margin-bottom: 0.5rem;
}

*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 10px;
    /* background: rgba(0, 0, 0, 0.1); */
    background-color: transparent;
}
  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(221, 221, 221, 0.4);
  }
  *::-webkit-scrollbar-thumb:hover {
    background: rgba(221, 221, 221, 0.4);
  }
  *::-webkit-scrollbar-thumb:active {
    background: rgba(221, 221, 221, 0.4);
  }
